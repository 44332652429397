define([
    "jquery",
    "menuaim"
],
function($) {
    "use strict";

    window.breakpoint = {stickyHeader: 300, desktop: 1024, tablet: 768}
    window.previousWidth = 0;
    window.oldScrollTop = 0;

    $(document).ready(function(){
        //moveMenu();

        window.previousWidth = window.innerWidth;
    });

    function blockScroll(scrollTop) {
        var body = jQuery('body');

        if(body.hasClass('noscroll')) {
            body.css('top', 0 - scrollTop);
            window.oldScrollTop = scrollTop;
        } else {
            $(window).scrollTop(window.oldScrollTop);
            body.css('top', '');
        }
    }

    $('.state').change(function () {
        $(this).parent().find('.state').each(function () {
            if (this.checked) {
                $(this).attr('aria-selected', 'true');

            } else {
                $(this).removeAttr('aria-selected');
            }
        });
    });

    // $(function(){
    //     // $('.level-top').hover(function() {
    //     //     $(this).addClass('hover');
    //     // }, function() {
    //     //     $(this).delay(2000).removeClass('hover');
    //     // })
    // });

    $(function()
    {
        $(".level-top").hover(function()
        {
            $(this).addClass("hover");
        }, function()
        {
            var link = $(this);
            window.setTimeout(function(){
                $(link).removeClass("hover");
            }, 150);
        });
    });

});