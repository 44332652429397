define([
    "jquery"
],
function($) {
    "use strict";

    $(document).ready(function(){

        $('.home-tabs-menu li').click(function(){

            var tabId = $(this).attr('id').substring(3);

            $('.home-tabs-menu li').removeClass('active');
            $('.tab-content').removeClass('active');

            $(this).addClass('active');
            $('#tab'+tabId+'-content').addClass('active');
        });

        $('.mobile-head').click(function(){

            var tabId = $(this).parents('.tab-content').attr('id').substring(3).replace('-content','');

            $('.home-tabs-menu li').removeClass('active');
            $('.tab-content').removeClass('active');

            $('#tab'+tabId).addClass('active');
            $(this).parents('.tab-content').addClass('active');

        });
    });
});