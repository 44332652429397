define([
    "jquery",
    'domReady!'
],
function($) {
    "use strict";

    function addTierLogic() {
        var changed = false;
        $('[data-order-qty]').each(function(){
            if ($(this).data('tierLogic')) {
                return;
            }
            changed = true;
            $(this).data('tierLogic', true);
            $(this).click(function(e){
                e.preventDefault();

                let qty = $('#qty');

                qty.val($(this).data('order-qty'));
                qty.change();
            });
        });
        if (changed) {
            changedQty();
        }
    }

    function changedQty() {
        let dataOrderQty = $('[data-order-qty]');
        let $qty = $('#qty');

        dataOrderQty.removeClass('active');

        let activeTier = null;
        let value = $qty.val();

        dataOrderQty.each(function(){
            if(value >= $(this).data('order-qty')) {
                activeTier = $(this);
            }
        });

        if (activeTier === null) {
            return;
        }

        activeTier.addClass('active');

        if($('.product-info-price .special-price').length) {
            $('.product-info-price .price-box .special-price .normal-price .price-wrapper span').html(activeTier.find('span.price').html());
        } else {
            $('.product-info-price .price-box .price').html(activeTier.find('span.price').html());
        }

        var finalPrice = activeTier.find('.price-wrapper.price-including-tax').attr("data-price-amount");
        var oldPrice = $('.old-price .price-container .price-wrapper.price-including-tax .price').html();

        if(finalPrice && oldPrice) {
            var match = /\d+(,\d+)?/.exec(oldPrice);
            oldPrice = Number(match[0].replace(',', '.'));

            finalPrice = Math.round(parseFloat(finalPrice) * 100) / 100;

            var discount = 0;

            if(finalPrice !== oldPrice && finalPrice !== 0 && oldPrice !== 0) {
                discount = (finalPrice / oldPrice - 1) * 100;
            }

            var discountLabel = $('.product-label-discount');

            if(discount === 0) {
                discountLabel.hide();
            } else {
                discount = Math.round(Math.abs(discount));
                discountLabel.show();
                $('.product-label-discount .product-label-inner .product-label-discount-amount').text(discount+'%');
            }
        }
    }

    // Select the node that will be observed for mutations
    const targetNode = $('.product-info-main')[0];

    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = function(mutationsList, observer) {
        // Use traditional 'for loops' for IE 11
        for(let mutation of mutationsList) {
            addTierLogic();
        }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    if (targetNode) {
        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    }

    addTierLogic();

    $('#qty').change(changedQty);
});
