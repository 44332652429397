define([
    "jquery"
],
function($) {
    "use strict";

    $(document).ready(function(){

        if(window.innerWidth < 768) {
            $('.product-info-main').appendTo('.product.overview');
        }

        $(".reviews-actions a").click(function() {
            console.log('Clicked');
            $('.review-write').remove();
            $('.block.review-add').addClass('active');
        });
    });

    $('.upsell').insertAfter('.columns');
});