define([
    "jquery"
],
function($) {
    "use strict";

    $(document).ready(function(){
        if($('body').hasClass('checkout-cart-index')){
            $('.select-qty').change(function(){
                var val = $(this).val();
                var input = $(this).parent().find('input');

                if(val == 'other') {
                    $(this).hide();
                    input.show();
                } else {
                    input.val(val);
                    $(this).parents('form').submit();
                }
            });

            $('.input-text.qty').change(function(){
                $(this).parents('form').submit();
            });
        }
    });
});