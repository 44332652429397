define([
    "jquery"
],
function($) {
    "use strict";

    $(document).ready(function(){
        $('.main-category-menu').click(function(){
            $(this).toggleClass('active');
        });

        moveTitle();
    });

    $(window).resize(function(){
        moveTitle();
    });

    function moveTitle() {
        if(window.innerWidth >= 1024) {
            $('.main-category-title').prependTo('.main-category-menu');
        } else {
            $('.main-category-title').insertBefore('.main-category-menu');
        }
    }
});